import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import cls from './IconsButtons.module.scss';
import { CartProduct } from '@/entities/Cart';
import { getFavCartLength } from '@/entities/Favorites';
import { getAuth } from '@/entities/authData';
import FavoriteIcon from '@/shared/assets/icons/Favorite.svg';
import BasketIcon from '@/shared/assets/icons/basket.svg';
import ProfileIcon from '@/shared/assets/icons/profile.svg';
import {
  getRouteProfile,
  getRouteSignIn,
  getRouteFavorites,
  getRouteCarts,
} from '@/shared/consts/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppSelector } from '@/shared/lib/hooks';
import { AppLink } from '@/shared/ui/AppLinks/AppLinks';
import { Badge } from '@/shared/ui/Badge/BadgeUI';
import { PTag } from '@/shared/ui/Paragraph/P';
import { VStack } from '@/shared/ui/Stack';

const FavoriteIconMemo = memo(() => <FavoriteIcon />);
const ProfileIconMemo = memo(() => <ProfileIcon />);
const BasketIconMemo = memo(() => <BasketIcon />);

export interface IconsButtonsProps {
  className?: string;
  baskets: CartProduct[];
}
export const IconsButtons = memo((props: IconsButtonsProps) => {
  const { className, baskets } = props;
  const { pathname } = useLocation();
  const isAuth = useAppSelector(getAuth);
  const badgeContent = useAppSelector(getFavCartLength);

  return (
    <div className={classNames(cls.IconsButtons, {}, [className])}>
      <div
        className={classNames(cls.btnIcons, {
          [cls.active]:
            pathname === getRouteProfile() || pathname === getRouteSignIn(),
        })}
      >
        <AppLink
          to={isAuth ? getRouteProfile() : getRouteSignIn()}
          className={cls.link}
        >
          <VStack gap={0.5} align='center'>
            <ProfileIconMemo />
            <PTag
              tage='P3'
              className={classNames('', {
                [cls.active]:
                  pathname === getRouteProfile() ||
                  pathname === getRouteSignIn(),
              })}
            >
              {isAuth ? 'Профиль' : 'Войти'}
            </PTag>
          </VStack>
        </AppLink>
      </div>
      {!!isAuth && (
        <div
          // name='Favorite'
          id='Favorite--btn'
          aria-label='Favorite'
          className={classNames(cls.btnIcons, {
            [cls.active]: pathname === getRouteFavorites(),
          })}
        >
          <AppLink to={getRouteFavorites()} className={cls.link}>
            <VStack gap={0.2} align='center'>
              <Badge count={badgeContent}>
                <FavoriteIconMemo />
              </Badge>
              <PTag
                tage='P3'
                className={classNames('', {
                  [cls.active]: pathname === getRouteFavorites(),
                })}
              >
                Избранное
              </PTag>
            </VStack>
          </AppLink>
        </div>
      )}
      <div
        // name='Basket'
        id='Basket-btn'
        aria-label='Basket'
        className={classNames(cls.btnIcons, {
          [cls.active]: pathname === getRouteCarts(),
        })}
      >
        <AppLink to={getRouteCarts()} className={cls.link}>
          <VStack gap={0.2} align='center'>
            <Badge count={baskets?.length}>
              <BasketIconMemo />
            </Badge>
            <PTag
              tage='P3'
              className={classNames('', {
                [cls.active]: pathname === getRouteCarts(),
              })}
            >
              Корзина
            </PTag>
          </VStack>
        </AppLink>
      </div>
    </div>
  );
});
