import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getDiscount, getTotalValue, uniqueCart } from '../lib/helpers';
import { getCartsReq } from '../services/getCardItems/getCardItems';
import { CartSchema } from '../types/cartSchema';

const initialState: CartSchema = {
  cartsItems: [],
  init: false,
  isLoading: false,
  notAuthCart: [],
};

export const cartSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(getCartsReq.pending, (state, { payload, meta }) => {
        state.error = undefined;
        state.isLoading = meta.arg.replace;
      })
      .addCase(getCartsReq.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.carts = payload;
        state.cartsItems = payload.items;
        state.init = true;
        state.notAuthCart = uniqueCart([
          ...payload.items,
          ...state.notAuthCart,
        ]);
      });
  },
  initialState,
  name: 'carts',
  reducers: {
    addToCart: (
      state,
      { payload }: PayloadAction<{ item: Product; qty: number }>,
    ) => {
      const { item, qty } = payload;
      const findItemIndex = state?.cartsItems?.findIndex(i => i.id === item.id);
      if (findItemIndex === -1) {
        const total = getTotalValue([item], qty).toString();
        const discount = getDiscount([item], qty);
        state.cartsItems = [
          {
            ...item,
            discount,
            quantity: qty,
            total,
          },
          ...state.cartsItems,
        ];
        state.notAuthCart = [...state.cartsItems];
        return state;
      }
      const finedItem = state.cartsItems[findItemIndex];
      const total = getTotalValue([finedItem], qty).toString();
      const discount = getDiscount([finedItem], qty);
      state.cartsItems[findItemIndex].quantity += qty;
      state.notAuthCart[findItemIndex].quantity += qty;
      state.cartsItems[findItemIndex].total = total;
      state.notAuthCart[findItemIndex].total = total;
      state.cartsItems[findItemIndex].discount = discount;
      state.notAuthCart[findItemIndex].discount = discount;
      return state;
    },
    changeNumItemsInCart: (
      state,
      { payload }: PayloadAction<{ id: string | number; qty: number }>,
    ) => {
      const { id, qty } = payload;
      const findItemIndex = state.cartsItems?.findIndex(i => i.id === id);
      const finedItem = state.cartsItems[findItemIndex];
      state.cartsItems[findItemIndex].quantity = qty;
      state.notAuthCart[findItemIndex].quantity = qty;
      const discount = getDiscount([finedItem], qty);
      const total = getTotalValue([finedItem], qty).toString();
      state.cartsItems[findItemIndex].total = total;
      state.notAuthCart[findItemIndex].total = total;
      state.cartsItems[findItemIndex].discount = discount;
      state.notAuthCart[findItemIndex].discount = discount;
    },

    removeItemFromCart: (
      state,
      { payload }: PayloadAction<{ id: string | number }>,
    ) => {
      state.cartsItems = state.cartsItems.filter(
        item => item.id !== payload.id,
      );
      state.notAuthCart = state.notAuthCart.filter(
        item => item.id !== payload.id,
      );
      return state;
    },
    setClearCart: state => {
      state.cartsItems = [];
      state.notAuthCart = [];
      state.carts = undefined;
      state.init = false;
    },
  },
});

export const { actions: cartActions } = cartSlice;
export const { reducer: cartReducer } = cartSlice;
