import {
  UnknownAction,
  configureStore,
  Reducer,
  ReducersMapObject,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { StateSchema, ThunkExtraArg, TStore } from './StateSchema';
import { createReducerManager } from './reducerManager';
import { cartReducer } from '@/entities/Cart';
import { categoriesReducer } from '@/entities/Categories';
import { favoritesReducer } from '@/entities/Favorites';
import { storeReducer } from '@/entities/Stores';
import { profileReducer } from '@/entities/UserProfile';
import { authDataReducer } from '@/entities/authData';
import { checkoutReducer } from '@/pages/CheckoutPage';
import { $api } from '@/shared/api/api';
import { rtkApi } from '@/shared/api/rtkApi';
import { headerReducer } from '@/widgets/Header';

export function createReduxStore(
  initialState?: StateSchema,
  asyncReducers?: ReducersMapObject<StateSchema>,
) {
  const rootReducer: ReducersMapObject<StateSchema> = {
    ...asyncReducers,
    Checkout: checkoutReducer,
    allStores: storeReducer,
    authData: authDataReducer,
    carts: cartReducer,
    categories: categoriesReducer,
    favorites: favoritesReducer,
    header: headerReducer,
    profileData: profileReducer,

    // last
    [rtkApi.reducerPath]: rtkApi.reducer,
  };

  const reducerManager = createReducerManager(rootReducer);
  const extraArg: ThunkExtraArg = {
    api: $api,
  };
  const persistedReducer = persistReducer(
    {
      key: 'root',
      storage,
      whitelist: ['carts', 'Checkout', 'categories', 'authData'],
    },
    reducerManager.reduce as Reducer<StateSchema>,
  ) as Reducer<StateSchema & PersistPartial, UnknownAction, StateSchema>;

  const store = configureStore({
    devTools: true,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        thunk: {
          extraArgument: extraArg,
        },
      }).concat(rtkApi.middleware),
    preloadedState: initialState,
    reducer: persistedReducer,
  }) as TStore;
  const persist = persistStore(store);
  store.reducerManager = reducerManager;
  return { persist, store };
}

export type AppDispatch = ThunkDispatch<
  StateSchema,
  ThunkExtraArg,
  UnknownAction
>;
