import { createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { getProfilePasswords } from '../../selectors/getPasswords/getPasswords';
import { getProfileData } from '../../selectors/getProfileForm/getProfileForm';
import { Passwords } from '../../types/Profile';
import { ThunkConfig } from '@/app/providers/StorProvider';
import { errorHandler } from '@/shared/lib/ErrorHandlers/errorHandler';

export const updatePasswordReq = createAsyncThunk<
  Iresponse<Passwords>,
  void,
  ThunkConfig<string[]>
>('profile/updatePassword', async (_, { extra, rejectWithValue, getState }) => {
  const formData = getProfilePasswords(getState());
  const userData = getProfileData(getState());
  const errorsValue: (keyof Passwords)[] = ['newPassword', 'oldPassword'];
  if (!formData) return rejectWithValue(errorsValue);
  const err = errorHandler(formData, errorsValue);
  if (err) return rejectWithValue(err);
  try {
    const response = await extra.api.post<Iresponse<Passwords>>(
      'users/auth/password-profile/change',
      {
        email: userData?.email,
        oldPassword: formData.oldPassword,
        password: formData.newPassword,
      },
    );

    enqueueSnackbar('Пароль успешно изменен', { variant: 'success' });
    return response.data;
  } catch (e) {
    return rejectWithValue(['service']);
  }
});
