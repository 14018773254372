import {
  RefObject,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import cls from './NewSecondHeader.module.scss';
import { getSearch } from '../../model/selector/getHeadersData';
import { searchReq } from '../../model/service/searchReq/search';
import { headerActions } from '../../model/slice/headerSlice';
import { Catalog } from '../CatalogMenu/CatalogMenu';
import { IconsButtons } from '../IconsButtons/IconsButtons';
import { SearchResult } from '../SearchResult/SearchResult';
import { SidebarUI } from '../Sidebar/SidebarUI';
import {
  addToCart,
  getCartItems,
  getCartsReq,
  getIsCartInit,
} from '@/entities/Cart';
import { fetchFavorites } from '@/entities/Favorites';
import { getAuth } from '@/entities/authData';
import CrossIcon from '@/shared/assets/icons/cross.svg';
import { getRouteSearchProducts } from '@/shared/consts/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
  useClickOutside,
  useModal,
} from '@/shared/lib/hooks';
import { createQueryParams } from '@/shared/lib/url';
import { Burger } from '@/shared/ui/Burger/Burger';
import { InputSearch } from '@/shared/ui/Inputs';
import { Layout } from '@/shared/ui/Layout/Layout';

export interface NewSecondHeaderProps {
  className?: string;
  isScrolling: boolean;
  isMobil: boolean;
}
export const NewSecondHeader = memo((props: NewSecondHeaderProps) => {
  const { className, isScrolling, isMobil } = props;
  const [searchParams] = useSearchParams();
  const searchFromUrl = searchParams.get('text');
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const popover = useRef() as RefObject<HTMLDivElement>;
  const query = useAppSelector(getSearch);
  const baskets = useAppSelector(getCartItems);
  const cartInit = useAppSelector(getIsCartInit);
  const isAuth = useAppSelector(getAuth);
  const [open, setOpen] = useState(false);
  const [isOpen, toggle] = useState(false);

  const closeHandel = useCallback(() => toggle(false), []);
  useClickOutside(popover, closeHandel);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const { close, isClosing, isMounted } = useModal({
    animationDelay: 250,
    isOpen: open,
    onClose,
  });

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onChange = useCallback(
    (value: string) => {
      toggle(true);
      dispatch(headerActions.setSearch(value));
      dispatch(searchReq());
    },
    [dispatch],
  );

  const onClear = useCallback(() => {
    dispatch(headerActions.setSearch(''));
    dispatch(searchReq());
    closeHandel();
  }, [closeHandel, dispatch]);

  const onSearch = useCallback(() => {
    navigate(getRouteSearchProducts());
    closeHandel();
    dispatch(searchReq());

    createQueryParams({
      text: query,
    });
  }, [closeHandel, dispatch, navigate, query]);

  const fetchCartAndFav = useCallback(async () => {
    if (isAuth) {
      dispatch(fetchFavorites({}));
      const res = await dispatch(getCartsReq({ replace: false }));
      if (res.meta.requestStatus === 'fulfilled' && !cartInit)
        dispatch(addToCart());
    }
  }, [cartInit, dispatch, isAuth]);

  useEffect(() => {
    if (searchFromUrl) dispatch(headerActions.setSearch(searchFromUrl));
  }, [dispatch, searchFromUrl]);

  useEffect(() => {
    fetchCartAndFav();
  }, [fetchCartAndFav]);

  return (
    <div
      className={classNames(
        cls.NewSecondHeader,
        { [cls.isScroll]: isScrolling },
        [className],
      )}
    >
      <Layout>
        <div className={cls.wrapper}>
          {!isMobil && <Catalog />}
          <div className={cls.second}>
            {!isMobil ? (
              <InputSearch
                placeholder='поиск'
                name='search'
                value={query}
                endIcon={<CrossIcon onClick={onClear} />}
                onSearch={onSearch}
                onChange={onChange}
                onClick={() => toggle(true)}
              />
            ) : (
              <Burger open={open} onOpen={onOpen} onClose={close} />
            )}
            <IconsButtons baskets={baskets} />
          </div>
        </div>
      </Layout>
      {!isMobil && !!isOpen && (
        <div
          ref={popover}
          className={classNames(cls.result, { [cls.isScroll]: isOpen }, [
            className,
          ])}
        >
          <SearchResult closeHandel={closeHandel} />
        </div>
      )}
      {!!isMobil && !!open && (
        <SidebarUI
          lazy
          isScrolling={isScrolling}
          open={open}
          close={close}
          isClosing={isClosing}
          isMounted={isMounted}
        />
      )}
    </div>
  );
});
