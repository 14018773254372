import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/app/providers/StorProvider';

export const fetchCategories = createAsyncThunk<
  Category[],
  void,
  ThunkConfig<string[]>
>('categories/fetchCategories', async (_, { extra, rejectWithValue }) => {
  try {
    const response = await extra.api.get<Category[]>('categories');
    return response.data;
  } catch (e) {
    return rejectWithValue(['service']);
  }
});
