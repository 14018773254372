import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchFavorites } from '../services/fetchFavorites';
import { FavoritesSchema } from '../types/favoritesTypes';
import { SortTypes } from '@/features/ProductSort';

const initialState: FavoritesSchema = {
  favoriteLength: 0,
  favoritesProduct: [],
  found: 1,
  isLoading: false,
  page: 1,
  size: 10,
  sort: 'Сначала дешевые',
};

const favoritesSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fetchFavorites.pending, (state, { payload, meta }) => {
        state.error = undefined;
        state.isLoading = !!meta.arg.replace;
      })
      .addCase(fetchFavorites.fulfilled, (state, { payload }) => {
        const prod = payload.data?.map(p => ({ ...p, isFavorite: true }));
        state.isLoading = false;
        state.error = undefined;
        state.favoritesProduct = prod || [];
        state.found = payload.count || 1;
        state.favoriteLength = prod?.length || 0;
      })
      .addCase(fetchFavorites.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      });
  },
  initialState,
  name: 'favorites',
  reducers: {
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setSearch: (state, { payload }: PayloadAction<string>) => {
      state.search = payload;
    },
    setSize: (state, { payload }: PayloadAction<number>) => {
      state.size = payload;
    },
    setSort: (state, { payload }: PayloadAction<SortTypes>) => {
      state.sort = payload;
    },
    setUnLike: (state, { payload }: PayloadAction<number>) => {
      const findItemIndex = state.favoritesProduct.findIndex(
        i => i.id === payload,
      );
      if (findItemIndex === -1) state.favoriteLength += 1;
      else {
        state.favoritesProduct[findItemIndex].isFavorite = false;
        state.favoritesProduct = state.favoritesProduct?.filter(
          i => i.isFavorite,
        );
        state.favoriteLength -= 1;
      }
    },
  },
});

export const { actions: favoritesActions } = favoritesSlice;
export const { reducer: favoritesReducer } = favoritesSlice;
