import { memo } from 'react';
import { lazyRetry } from '@/shared/lib/lazyRetry/lazyWithRetry';
import { SuspenseWrapper } from '@/shared/ui/SuspenseWrapper/SuspenseWrapper';

export const Content = lazyRetry(() => import('./Logo'));

export const Logo = memo(() => (
  <SuspenseWrapper loaderStyle={{ maxWidth: 40, minHeight: 30 }}>
    <Content />
  </SuspenseWrapper>
));
