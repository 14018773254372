import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/app/providers/StorProvider';

export const fetchPopularCategory = createAsyncThunk<
  Category[],
  void,
  ThunkConfig<string[]>
>('categories/fetchPopularCategory', async (_, { extra, rejectWithValue }) => {
  try {
    const response = await extra.api.get<Category[]>('/categories/popular');
    return response.data;
  } catch (e) {
    return rejectWithValue(['service']);
  }
});
