import { createAsyncThunk } from '@reduxjs/toolkit';
import { getQuery } from '../selectors/getFavoritesData';
import { ThunkConfig } from '@/app/providers/StorProvider';

interface fetchFavoritesProps {
  replace?: boolean;
}

export const fetchFavorites = createAsyncThunk<
  Iresponse<Product[]>,
  fetchFavoritesProps,
  ThunkConfig<string>
>(
  'favorites/fetchFavorites',
  async (_props, { extra, rejectWithValue, getState, dispatch }) => {
    // const orderBy = getOrders(sort);
    const query = getQuery(getState());
    try {
      const response = await extra.api.get<Iresponse<Product[]>>(
        `products/favorites${query}`,
      );
      return response.data;
    } catch (e) {
      return rejectWithValue('Что-то пошло не так, попробуйте чуть позже');
    }
  },
);
