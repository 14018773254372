import { memo } from 'react';
import { CategorySkeleton } from './CategorySkeleton';
import cls from './CategoryTable.module.scss';
import {
  getCategoriesError,
  getCategoriesIsLoading,
} from '../../model/selectors/getCatalogs';
import noImage from '@/shared/assets/images/transparentlogo.png';
import { useAppSelector } from '@/shared/lib/hooks';
import { AppImage } from '@/shared/ui/AppImage';
import { AppLink } from '@/shared/ui/AppLinks/AppLinks';
import { Content } from '@/shared/ui/Content/Content';
import { Htag } from '@/shared/ui/Htage/Htage';
import { Skeleton } from '@/shared/ui/Skeleton';
import { VStack } from '@/shared/ui/Stack';

export interface CategoryTableProps {
  categories?: Category[];
  onHandleNavigate: (id?: number) => string;
  onChooseItem?: (item: Category) => void;
  name: string;
}
export const CategoryTable = memo((props: CategoryTableProps) => {
  const { categories, onHandleNavigate, onChooseItem, name } = props;
  const isLoading = useAppSelector(getCategoriesIsLoading);
  const error = useAppSelector(getCategoriesError);
  return (
    <VStack
      max
      gap={2}
      align='start'
      justify='start'
      className={cls.categoriesWrapper}
    >
      <Htag tage='h1'>
        {isLoading && name !== 'КАТАЛОГ' ? 'loading...' : name}
      </Htag>
      <div className={cls.categories}>
        <Content
          emptyText='Нет Категории'
          isEmpty={!categories?.length}
          errorMessage={{ error, text: 'что то пошло не так...' }}
          isLoading={isLoading}
          LoadingContent={<CategorySkeleton />}
        >
          <table className={cls.table}>
            <tbody>
              <tr className={cls.tr}>
                {categories?.map(item => (
                  <td key={item.id} onClick={() => onChooseItem?.(item)}>
                    <AppLink
                      to={onHandleNavigate(item.id)}
                      className={cls.link}
                    >
                      <VStack
                        className={cls.catalog}
                        align='center'
                        justify='center'
                      >
                        <div style={{ maxHeight: '8rem', maxWidth: '18rem' }}>
                          <AppImage
                            fallback={<Skeleton width={80} height={80} />}
                            src={(item.imageUrl && item.imageUrl) || noImage}
                            alt={(item.imageUrl && item.imageUrl) || 'shishka'}
                            style={{
                              height: '100%',
                              objectFit: 'contain',
                              width: '100%',
                            }}
                          />
                        </div>
                        <Htag tage='h3' className={cls.H3}>
                          {item.name || ''}
                        </Htag>
                      </VStack>
                    </AppLink>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </Content>
      </div>
    </VStack>
  );
});
