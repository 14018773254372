import { createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { Profile } from '../../types/Profile';
import { ThunkConfig } from '@/app/providers/StorProvider';
import { getUseId } from '@/entities/authData';

export const fetchProfileData = createAsyncThunk<
  Profile,
  void,
  ThunkConfig<string[]>
>(
  'profile/fetchProfileData',
  async (_, { extra, rejectWithValue, getState }) => {
    try {
      const userId = getUseId(getState());
      if (!userId) return rejectWithValue(['service']);
      const response = await extra.api.get<Profile>(`users/profile/${userId}`);
      return response.data;
    } catch (e) {
      enqueueSnackbar('Что-то пошло не так, попробуйте чуть позже', {
        variant: 'error',
      });
      return rejectWithValue(['service']);
    }
  },
);
