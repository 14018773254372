import { createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { getProfileForm } from '../../selectors/getProfileForm/getProfileForm';
import { Profile } from '../../types/Profile';
import { ThunkConfig } from '@/app/providers/StorProvider';
import { errorHandler } from '@/shared/lib/ErrorHandlers/errorHandler';

export const updateProfileData = createAsyncThunk<
  Profile,
  void,
  ThunkConfig<string[]>
>(
  'profile/updateProfileData',
  async (_, { extra, rejectWithValue, getState }) => {
    const formData = getProfileForm(getState()) as Profile;
    const errorsValue: (keyof Profile)[] = [
      'birthday',
      'city',
      'email',
      'phoneNumber',
      'fullName',
      'sex',
    ];
    if (!formData) return rejectWithValue(errorsValue);
    const form = { ...formData };
    const err = errorHandler(formData, errorsValue);
    if (err) return rejectWithValue(err);
    const { id, email, fullName, phoneNumber, birthday, city, sex } = form;
    try {
      const response = await extra.api.patch<Profile>(
        `/users/profile/${form.id}`,
        {
          birthday,
          city,
          email,
          fullName,
          id,
          phoneNumber,
          sex,
        },
      );
      enqueueSnackbar('Данные успешно были обновлены', { variant: 'success' });
      return response.data;
    } catch (e) {
      return rejectWithValue(['Что-то пошло не так, попробуйте чуть позже']);
    }
  },
);
