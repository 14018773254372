import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStores } from '../service/fetchStores';
import { fetchStoresNames } from '../service/fetchStoresNames';
import { StoreSchema } from '../types/stores';

const initialState: StoreSchema = {
  isLoading: false,
  isTabsLoading: false,
  page: 1,
  size: 10,
};

const storeSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fetchStores.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchStores.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.allStores = payload.data;
        state.totalPage = payload.count;
      })
      .addCase(fetchStores.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload;
      })
      .addCase(fetchStoresNames.pending, state => {
        state.isTabsLoading = true;
      })
      .addCase(fetchStoresNames.fulfilled, (state, { payload }) => {
        state.isTabsLoading = false;
        state.storesTabs = payload;
      })
      .addCase(fetchStoresNames.rejected, (state, { payload }) => {
        state.isTabsLoading = false;
        state.errorsTabs = payload;
      });
  },
  initialState,
  name: 'stores',
  reducers: {
    setChooseCity: (
      state,
      { payload }: PayloadAction<Autocomplete<string>>,
    ) => {
      state.selectedCity = payload;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setSearch: (state, { payload }: PayloadAction<string>) => {
      state.search = payload;
    },
    setSize: (state, { payload }: PayloadAction<number>) => {
      state.size = payload;
    },
  },
});

export const { actions: storeActions } = storeSlice;
export const { reducer: storeReducer } = storeSlice;
