import { memo, useCallback, useEffect, useState } from 'react';
import cls from './Header.module.scss';
import { fetchCity } from '../../model/service/fetchCity/fetchLocation';
import { fetchLocation } from '../../model/service/fetchCity/fetchLocations';
import { HeaderFirstLine } from '../HeaderFirstLine/HeaderFirstLine';
import { NewSecondHeader } from '../NewSecondHeader/NewSecondHeader';
import { classNames } from '@/shared/lib/classNames/classNames';
import {
  useAppDispatch,
  useEffectOnce,
  useMediaQuery,
} from '@/shared/lib/hooks';

export interface HeaderProps {
  className?: string;
}
export const Header = memo((props: HeaderProps) => {
  const { className } = props;
  const dispatch = useAppDispatch();
  const mobile = useMediaQuery('(max-width: 768px)');
  const [isScrolling, setIsScrolling] = useState(false);
  const handleSize = useCallback(
    () => setIsScrolling(Boolean(window.scrollY > 10)),
    [],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleSize);
    return () => window.removeEventListener('scroll', handleSize);
  }, [handleSize]);

  useEffectOnce(() => {
    dispatch(fetchLocation());
    dispatch(fetchCity());
  });

  return (
    <>
      {!isScrolling && !mobile && (
        <div
          className={classNames(
            cls.Header,
            { [cls.headerScroll]: isScrolling },
            [className],
          )}
        >
          <header>
            <HeaderFirstLine />
          </header>
        </div>
      )}
      <NewSecondHeader isScrolling={isScrolling} isMobil={mobile} />
    </>
  );
});
