import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from '@/app/providers/StorProvider';

export const getCart = (state: StateSchema) => state?.carts?.carts;
export const getCartItems = (state: StateSchema) => state?.carts?.cartsItems;
export const getNotAuthCart = (state: StateSchema) => state?.carts?.notAuthCart;
export const getIsLoading = (state: StateSchema) => state?.carts?.isLoading;
export const getIsCartInit = (state: StateSchema) => state?.carts?.init;
export const getCountCarts = createSelector(getCartItems, items => {
  if (items) {
    if (items.length === 1) return `${items?.length} товар`;
    if (items?.length <= 4 || items?.length >= 2)
      return `${items?.length} товара`;
    return `${items?.length} товаров`;
  }
  return '';
});

export const getTotal = createSelector(getCartItems, carts => {
  if (carts) {
    const totalPrice = carts.reduce((acc, item) => {
      if (item.oldPrice) return acc + Number(item.oldPrice) * item.quantity;
      return acc + Number(item.price) * item.quantity;
    }, 0);
    return totalPrice;
  }
  return 0;
});

export const getTotalWithDiscount = createSelector(getCartItems, carts => {
  if (carts) {
    const totalPrice = carts.reduce(
      (acc, item) => acc + Number(item.price) * item.quantity,
      0,
    );
    return totalPrice;
  }
  return 0;
});

export const getCartSavings = createSelector(getCartItems, items => {
  if (items) {
    const totalPriceDifference = items.reduce((sum, item) => {
      const priceDifference = Number(item.oldPrice)
        ? Number(item.oldPrice) - Number(item.price)
        : 0;
      return sum + priceDifference * item.quantity;
    }, 0);

    return totalPriceDifference;
  }
  return 0;
});
