import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from '@/app/providers/StorProvider';

export const getIsAuth = (state: StateSchema) => state.authData.isAuth ?? false;
export const getUseId = (state: StateSchema) => state.authData.authData?.id;
export const getAccessToken = (state: StateSchema) =>
  state.authData.authData?.accessToken;

export const getAuth = createSelector(
  getIsAuth,
  getAccessToken,
  (auth, token) => {
    if (token || auth) return true;
    return false;
  },
);
