import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { CityRuRes } from '../../types/locations';
import { ThunkConfig } from '@/app/providers/StorProvider';

export const fetchCityRu = createAsyncThunk<
  CityRuRes,
  string,
  ThunkConfig<string[]>
>('headers/fetchCityRu', async (city, { extra, rejectWithValue, getState }) => {
  try {
    const response = await axios.get<CityRuRes>(
      `https://api.geoapify.com/v1/geocode/search?lang=ru&text=${city}&format=json&apiKey=${process.env.API_LOCATION_TOKEN}`,
    );
    return response.data;
  } catch (e) {
    return rejectWithValue(['Что-то пошло не так, попробуйте чуть позже']);
  }
});
