/* eslint-disable react/no-danger */
import { memo, useCallback } from 'react';
import cls from './SearchResult.module.scss';
import {
  getSearch,
  getSearchProducts,
} from '../../model/selector/getHeadersData';
import {
  getRouteProductDetails,
  getRouteSearchProducts,
} from '@/shared/consts/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppNavigate, useAppSelector } from '@/shared/lib/hooks';
import { createQueryParams } from '@/shared/lib/url';
import { AppImage } from '@/shared/ui/AppImage';
import { AppLink } from '@/shared/ui/AppLinks/AppLinks';
import { ButtonUi } from '@/shared/ui/Buttons/ButtonUi';
import { Skeleton } from '@/shared/ui/Skeleton';
import { HStack, VStack } from '@/shared/ui/Stack';

export interface SearchResultProps {
  className?: string;
  closeHandel: () => void;
}
export const SearchResult = memo((props: SearchResultProps) => {
  const { className, closeHandel } = props;
  const navigate = useAppNavigate();
  const query = useAppSelector(getSearch);
  const searchProduct = useAppSelector(getSearchProducts);
  const highlightMatch = useCallback(
    (text: string) => {
      const regex = new RegExp(`(${query})`, 'gi');
      return text.replace(regex, '<span style="color: #00845B;">$1</span>');
    },
    [query],
  );

  const onNavigateToSearchPage = useCallback(() => {
    navigate(getRouteSearchProducts());
    closeHandel();
    createQueryParams({
      text: query,
    });
  }, [closeHandel, navigate, query]);
  return (
    <div className={classNames(cls.SearchResult, {}, [className])}>
      <VStack className={cls.wrapper}>
        <HStack max gap={3} justify='between' align='start'>
          {/* <VStack gap={1} align='start'>
            {searchCategory?.map((result, index) => (
              <AppLink
                key={index}
                to={result.url}
                className={cls.link}
                onClick={closeHandel}
              >
                <span
                  className={cls.searchItem}
                  dangerouslySetInnerHTML={{
                    __html: highlightMatch(result.name || ''),
                  }}
                />
              </AppLink>
            ))}
          </VStack> */}
          <VStack gap={0.5} align='start'>
            {searchProduct?.map(({ id, name, imageUrl }, index) => (
              <AppLink
                key={index}
                to={getRouteProductDetails(id)}
                className={cls.link}
                onClick={closeHandel}
              >
                <HStack max gap={1}>
                  <AppImage
                    className={cls.image}
                    src={imageUrl || ''}
                    fallback={<Skeleton width={90} height={90} border='15px' />}
                  />
                  <div className={cls.resultItems}>
                    <div
                      className={cls.searchItem}
                      dangerouslySetInnerHTML={{
                        __html: highlightMatch(name || ''),
                      }}
                    />
                  </div>
                </HStack>
              </AppLink>
            ))}
            <ButtonUi
              className={cls.btn}
              layOut='TextOnly'
              name='see_res'
              theme='secondary'
              onClick={onNavigateToSearchPage}
            >
              посмотреть все результаты
            </ButtonUi>
          </VStack>
        </HStack>
      </VStack>
    </div>
  );
});
