import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from '@/app/providers/StorProvider';

export const getCategories = (state: StateSchema) =>
  state.categories.categories;

export const getCategoriesError = (state: StateSchema) =>
  state.categories.error;
export const getCategoriesIsLoading = (state: StateSchema) =>
  state.categories.isLoading;

export const getPopularCategories = (state: StateSchema) =>
  state.categories.popularCategories;

export const getPopularError = (state: StateSchema) =>
  state.categories.errorPopular;
export const getPopularIsLoading = (state: StateSchema) =>
  state.categories.isPopularLoading;

export const getSelectedCategory = (state: StateSchema) =>
  state.categories.selectedCategory;

export const getSelectedCategoryHeader = (state: StateSchema) =>
  state.categories.selectedHeaderCategory;

export const getParentCatagories = createSelector(getCategories, allItems => {
  const category = allItems?.filter(i => i.parentCategory === null);
  return category;
});

export const getSubCatagories = createSelector(
  getCategories,
  getSelectedCategory,
  (allItems, selected) => {
    const te = allItems?.filter(
      item => item.parentCategory?.name === selected?.name,
    );
    return te;
  },
);

export const getSubCatagoriesHeader = createSelector(
  getCategories,
  getSelectedCategoryHeader,
  (allItems, selected) => {
    const te = allItems?.filter(
      item => item.parentCategory?.name === selected?.name,
    );
    return te;
  },
);
