import { createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { ThunkConfig } from '@/app/providers/StorProvider';

export const fetchStoresNames = createAsyncThunk<
  string[],
  void,
  ThunkConfig<string[]>
>('stores/fetchStoresNames', async (_, { extra, rejectWithValue }) => {
  try {
    const response = await extra.api.get<string[]>('stores/cities');
    return response.data;
  } catch (e) {
    enqueueSnackbar('Что-то пошло не так, попробуйте чуть позже', {
      variant: 'error',
    });
    return rejectWithValue(['service']);
  }
});
