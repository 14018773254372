import { memo } from 'react';
import cls from './CategoriesMenu.module.scss';
import { classNames } from '@/shared/lib/classNames/classNames';
import { lazyRetry } from '@/shared/lib/lazyRetry/lazyWithRetry';
import { SuspenseWrapper } from '@/shared/ui/SuspenseWrapper/SuspenseWrapper';

export interface CategoriesMenuProps {
  onClose: () => void;
}

export const Content = lazyRetry(() => import('./CategoriesMenu'));

export const CategoriesMenu = memo((props: CategoriesMenuProps) => (
  <div className={classNames(cls.CategoriesMenu, {}, [])}>
    <SuspenseWrapper>
      <Content {...props} />
    </SuspenseWrapper>
  </div>
));
