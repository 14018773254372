import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchCategories } from '../service/fetchCategory';
import { fetchPopularCategory } from '../service/fetchPopularCategory';
import { CategoriesSchema } from '../types/catalogs';

const initialState: CategoriesSchema = {
  isLoading: false,
  isPopularLoading: false,
};

export const categoriesSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fetchCategories.pending, state => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = undefined;
        state.categories = payload;
      })
      .addCase(fetchCategories.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(fetchPopularCategory.pending, state => {
        state.isPopularLoading = true;
      })
      .addCase(fetchPopularCategory.fulfilled, (state, { payload }) => {
        state.isPopularLoading = false;
        state.errorPopular = undefined;
        state.popularCategories = payload;
      })
      .addCase(fetchPopularCategory.rejected, (state, { payload }) => {
        state.isPopularLoading = false;
        state.errorPopular = payload;
      });
  },
  initialState,
  name: 'groupsCatalog',
  reducers: {
    setSelectCategory: (state, { payload }: PayloadAction<Category>) => {
      state.selectedCategory = payload;
    },
    setSelectCategoryHeader: (state, { payload }: PayloadAction<Category>) => {
      state.selectedHeaderCategory = payload;
    },
  },
});

export const { actions: categoriesActions } = categoriesSlice;
export const { reducer: categoriesReducer } = categoriesSlice;
