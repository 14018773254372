import { memo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import cls from './HeaderFirstLine.module.scss';
import { firstHeaderItems } from '../../model/selector/getFirstHeaderItems/getFirstHeaderItems';
import { getCity } from '../../model/selector/getHeadersData';
import { Location } from '@/features/Location';
import { Logo } from '@/shared/assets/icons/Logo';
import { getRouteMain } from '@/shared/consts/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppNavigate, useAppSelector } from '@/shared/lib/hooks';
import { AppLink } from '@/shared/ui/AppLinks/AppLinks';
import { Layout } from '@/shared/ui/Layout/Layout';
import { PTag } from '@/shared/ui/Paragraph/P';
import { HStack } from '@/shared/ui/Stack';

export interface HeaderFirstLineProps {
  className?: string;
}
const BosLogoMemo = memo(() => <Logo />);
export const HeaderFirstLine = memo((props: HeaderFirstLineProps) => {
  const { className } = props;
  const { pathname } = useLocation();
  const navigate = useAppNavigate();
  const city = useAppSelector(getCity);
  const onNavigate = useCallback(() => {
    navigate(getRouteMain());
  }, [navigate]);
  return (
    <Layout className={cls.layout}>
      <div className={classNames(cls.HeaderFirstLine, {}, [className])}>
        <HStack gap={0.5}>
          <div style={{ cursor: 'pointer' }} onClick={onNavigate}>
            <BosLogoMemo />
          </div>
          <Location city={city} />
        </HStack>
        <div className={cls.menu}>
          {firstHeaderItems.map(item => (
            <AppLink
              key={item.title}
              to={item.link}
              className={classNames('', {
                [cls.active]: pathname === item.link,
              })}
            >
              <PTag tage='P2'>{item.title}</PTag>
            </AppLink>
          ))}
        </div>
      </div>
    </Layout>
  );
});
