import { CartProduct } from '../types/cartSchema';

export const getTotalValue = (items: Product[], qty: number) =>
  items?.reduce((acc, item) => acc + Number(item.price) * qty, 0);

export const getDiscount = (items: Product[], qty: number) => {
  if (items) {
    const totalPriceDifference = items.reduce((sum, item) => {
      const priceDifference = Number(item.oldPrice)
        ? Number(item.oldPrice) - Number(item.price)
        : 0;
      return sum + priceDifference * qty;
    }, 0);

    return totalPriceDifference;
  }
  return 0;
};

export const uniqueCart = (carts: CartProduct[]) => {
  const uniqueCarts = carts.reduce((acc: CartProduct[], curr) => {
    const existingCart = acc.find(item => item.id === curr.id);
    if (!existingCart) acc.push(curr);
    return acc;
  }, []);
  return uniqueCarts;
};
