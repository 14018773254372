import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSearch } from '../../selector/getHeadersData';
import { ThunkConfig } from '@/app/providers/StorProvider';
import { getQueryParams } from '@/shared/lib/url';

export const searchReq = createAsyncThunk<
  Iresponse<Product[]>,
  void,
  ThunkConfig<string[]>
>('footer/search', async (_, { extra, rejectWithValue, getState }) => {
  const search = getSearch(getState());
  const query = getQueryParams({
    limit: '6',
    page: '1',
    search,
  });
  try {
    const response = await extra.api.get<Iresponse<Product[]>>(
      `products${query}`,
    );
    return response.data;
  } catch (e) {
    return rejectWithValue(['Что-то пошло не так, попробуйте чуть позже']);
  }
});
