import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { checkoutReq } from '../services/checkout';
import { CheckoutForm, CheckoutSchema } from '../types/checkoutTypes';

const initialState: CheckoutSchema = {
  isLoading: false,
  store: '',
  successCheckout: false,
};

const checkoutSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(checkoutReq.pending, state => {
        state.error = undefined;
        state.isLoading = true;
        state.successCheckout = false;
      })
      .addCase(checkoutReq.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = undefined;
        state.orderInformation = payload;
        state.successCheckout = true;
      })
      .addCase(checkoutReq.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
        state.successCheckout = false;
      });
  },
  initialState,
  name: 'checkout',
  reducers: {
    setClear: state => {
      state.successCheckout = false;
      state.isLoading = false;
    },
    setError: (state, { payload }: PayloadAction<string[]>) => {
      state.error = payload;
    },
    setFormData: (state, { payload }: PayloadAction<CheckoutForm>) => {
      state.formData = { ...state.formData, ...payload };
    },
    setStore: (state, { payload }: PayloadAction<string>) => {
      state.store = payload;
    },
  },
});

export const { actions: checkoutActions } = checkoutSlice;
export const { reducer: checkoutReducer } = checkoutSlice;
