import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from '@/app/providers/StorProvider';

export const getSearchResult = (state: StateSchema) => state?.header.result;
export const getSearch = (state: StateSchema) => state?.header.search || '';
export const getSearchProducts = (state: StateSchema) => state?.header.result;

// export const getSearchProducts = createSelector(getSearchResult, res =>
//   res?.filter(r => r.type !== 'category'),
// );
// export const getSearchCategory = createSelector(getSearchResult, res =>
//   res?.filter(r => r.type !== 'product'),
// );
export const getLocations = (state: StateSchema) => state?.header.locationData;
export const getCity = (state: StateSchema) => state?.header.city || '';

export const getUserGeo = createSelector(getLocations, location => {
  if (location)
    return {
      userXLocation: location?.location?.latitude?.toString(),
      userYLocation: location?.location?.longitude?.toString(),
    };
});
