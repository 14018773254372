import { Navigate, useLocation } from 'react-router-dom';
import { getAuth } from '@/entities/authData';
import { HISTORY_PATH } from '@/shared/consts/localStorages';
import { getRouteMain } from '@/shared/consts/router';
import { useAppSelector } from '@/shared/lib/hooks';

export function RequireAuth({ children }: { children: JSX.Element }) {
  const isAuth = useAppSelector(getAuth);
  const historyPath = localStorage.getItem(HISTORY_PATH);
  // const auth = true;
  const location = useLocation();

  if (!isAuth)
    return <Navigate replace to={getRouteMain()} state={{ from: location }} />;
  if (historyPath)
    return <Navigate replace to={historyPath} state={{ from: location }} />;

  return children;
}
