import { createAsyncThunk } from '@reduxjs/toolkit';
import { errors } from '../lib/errorsConsts';
import { getFormData } from '../selectors/getCheckoutData';
import { OrderInfo } from '../types/checkoutTypes';
import { ThunkConfig } from '@/app/providers/StorProvider';
import { errorHandler } from '@/shared/lib/ErrorHandlers/errorHandler';

export const checkoutReq = createAsyncThunk<
  OrderInfo,
  void,
  ThunkConfig<string[]>
>(
  'checkout/checkout',
  async (_, { extra, rejectWithValue, getState, dispatch }) => {
    const form = getFormData(getState());
    if (!form) return rejectWithValue(errors);
    const err = errorHandler(form, errors);
    if (err) return rejectWithValue(err);
    try {
      const response = await extra.api.post<OrderInfo>('users/orders', {
        isConfirmHandlePersonalData: form.isConfirmHandlePersonalData,
        phoneNumber: form.phoneNumber,
        storeId: form.storeId,
      });
      return response.data;
    } catch (e) {
      return rejectWithValue(['service']);
    }
  },
);
