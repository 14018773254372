import { memo } from 'react';
import cls from './FirstFooter.module.scss';
import { fetchCategories, getCategories } from '@/entities/Categories';
import { TelegramIcon, VkIcon } from '@/shared/assets/icons/SocialMedia';
import logo from '@/shared/assets/images/logo/logoFooter.png';
import { classNames } from '@/shared/lib/classNames/classNames';
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from '@/shared/lib/hooks';
import { AppImage } from '@/shared/ui/AppImage';
import { HStack } from '@/shared/ui/Stack';

export interface FirstFooterProps {
  className?: string;
}

export const FirstFooter = memo((props: FirstFooterProps) => {
  const { className } = props;
  const dispatch = useAppDispatch();
  const categories = useAppSelector(getCategories);

  const hooks = categories?.slice(0, 4);
  const vape = categories?.slice(0, 4);
  const pactaItems = categories?.slice(0, 4);
  const pactaItemsSecond = categories?.slice(0, 5);

  useEffectOnce(() => {
    dispatch(fetchCategories());
  });
  return (
    <div className={classNames(cls.FirstFooter, {}, [className])}>
      <HStack
        max
        align='center'
        justify='between'
        gap={1.75}
        className={cls.itemsWrapper}
      >
        <AppImage src={logo} alt='Шишка' className={cls.img} />
        <HStack
          gap={0.75}
          align='center'
          justify='start'
          className={cls.itemsWrapper}
        >
          <a
            href='https://vk.com/shishka_nino'
            target='_blank'
            rel='noreferrer noopener'
            aria-label='vk'
          >
            <VkIcon />
          </a>
          <a
            href='https://t.me/shishka_vape'
            target='_blank'
            rel='noreferrer'
            aria-label='telegram'
          >
            <TelegramIcon />
          </a>
        </HStack>
      </HStack>
    </div>
  );
});
