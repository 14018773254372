import { memo } from 'react';
import { lazyRetry } from '@/shared/lib/lazyRetry/lazyWithRetry';
import { SuspenseWrapper } from '@/shared/ui/SuspenseWrapper/SuspenseWrapper';

export interface LocationProps {
  className?: string;
  city: string;
}
export const Content = lazyRetry(() => import('./LocationUI'));

export const Location = memo((props: LocationProps) => (
  <SuspenseWrapper loaderStyle={{ maxWidth: 40, minHeight: 30 }}>
    <Content {...props} />
  </SuspenseWrapper>
));
