import { StateSchema } from '@/app/providers/StorProvider';

export const getProfileForm = (state: StateSchema) => state?.profileData?.form;
export const getErrorForm = (state: StateSchema) =>
  state?.profileData.error ?? [];
export const getProfileData = (state: StateSchema) => state?.profileData?.data;
export const getProfileIsLoading = (state: StateSchema) =>
  state?.profileData?.isLoading;

export const getUpdateLoading = (state: StateSchema) =>
  state?.profileData?.isUpdateLoading;
