import { memo } from 'react';
import { lazyRetry } from '@/shared/lib/lazyRetry/lazyWithRetry';
import { SuspenseWrapper } from '@/shared/ui/SuspenseWrapper/SuspenseWrapper';

export const Content = lazyRetry(() => import('./ProfileDetails'));

export interface ProfileDetailsProps {
  className?: string;
}
export const ProfileDetails = memo((props: ProfileDetailsProps) => (
  <SuspenseWrapper>
    <Content {...props} />
  </SuspenseWrapper>
));
