import { Suspense, memo, useCallback } from 'react';
import cls from './ModalPrivacyPolicy.module.scss';
import { classNames } from '@/shared/lib/classNames/classNames';
import { lazyRetry } from '@/shared/lib/lazyRetry/lazyWithRetry';
import { LoaderUi } from '@/shared/ui/Loader/LoaderUi';
import { CustomModal } from '@/shared/ui/Modal/ModalUi';
import { VStack } from '@/shared/ui/Stack';

const Content = lazyRetry(() => import('./ContentModal'));
export interface ModalPrivacyPolicyProps {
  className?: string;
  open: boolean;
  onClose: () => void;
}
export const ModalPrivacyPolicy = memo((props: ModalPrivacyPolicyProps) => {
  const { className, onClose, open } = props;
  const loaderUI = useCallback(
    () => (
      <VStack max className={cls.loader} justify='center' align='center'>
        <LoaderUi />
      </VStack>
    ),
    [],
  );
  return (
    <CustomModal lazy isOpen={open} className={className} onClose={onClose}>
      <div className={classNames(cls.ModalPrivacyPolicy, {}, [className])}>
        <Suspense fallback={loaderUI()}>
          <Content onClose={onClose} />
        </Suspense>
      </div>
    </CustomModal>
  );
});
