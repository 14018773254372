import { createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { getQuery } from '../selectors/getStoresData';
import { ThunkConfig } from '@/app/providers/StorProvider';

export const fetchStores = createAsyncThunk<
  Iresponse<StoreTypes[]>,
  void,
  ThunkConfig<string[]>
>('stores/fetchStores', async (_, { extra, rejectWithValue, getState }) => {
  try {
    const query = getQuery(getState());
    const response = await extra.api.get<Iresponse<StoreTypes[]>>(
      `stores${query}`,
    );
    return response.data;
  } catch (e) {
    enqueueSnackbar('Что-то пошло не так, попробуйте чуть позже', {
      variant: 'error',
    });
    return rejectWithValue(['service']);
  }
});
