import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ResData } from '../../types/locations';
import { ThunkConfig } from '@/app/providers/StorProvider';

export const fetchLocation = createAsyncThunk<
  ResData,
  void,
  ThunkConfig<string[]>
>('headers/fetchLocation', async (_, { extra, rejectWithValue, getState }) => {
  try {
    const response = await axios.get<ResData>(
      `https://api.geoapify.com/v1/ipinfo?&apiKey=${process.env.API_LOCATION_TOKEN}&lang=ru`,
    );
    return response.data;
  } catch (e) {
    return rejectWithValue(['Что-то пошло не так, попробуйте чуть позже']);
  }
});
