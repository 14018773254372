import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from '@/app/providers/StorProvider';
import { getQueryParams } from '@/shared/lib/url';

export const getSort = (state: StateSchema) =>
  state.favorites?.sort || 'Высокий рейтинг';

export const getSearch = (state: StateSchema) => state.favorites?.search;
export const getFavCartLength = (state: StateSchema) =>
  state.favorites?.favoriteLength;
export const getPage = (state: StateSchema) => state.favorites?.page;
export const getSize = (state: StateSchema) => state.favorites?.size;
export const getFavoriteData = (state: StateSchema) =>
  state.favorites?.favoritesProduct;

export const getFavoriteIsLoading = (state: StateSchema) =>
  state.favorites?.isLoading || false;

export const getFavoriteError = (state: StateSchema) => state.favorites?.error;

export const getQuery = createSelector(
  getSize,
  getSearch,
  getPage,
  (size, search, page) =>
    getQueryParams({
      limit: size.toString(),
      page: page.toString(),
      search,
    }),
);
