import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCity } from '../service/fetchCity/fetchLocation';
import { fetchLocation } from '../service/fetchCity/fetchLocations';
import { searchReq } from '../service/searchReq/search';
import { HeaderSchema } from '../types/headerTypes';

const initialState: HeaderSchema = {
  isSearchLoading: false,
  search: '',
};

export const headerSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(searchReq.pending, state => {
        state.isSearchLoading = true;
      })
      .addCase(searchReq.fulfilled, (state, { payload }) => {
        state.isSearchLoading = false;
        state.errors = [];
        state.result = payload.data;
      })
      .addCase(searchReq.rejected, (state, { payload }) => {
        state.isSearchLoading = false;
        state.errors = payload || [];
      })
      .addCase(fetchLocation.fulfilled, (state, { payload }) => {
        state.errors = [];
        state.locationData = payload;
      })
      .addCase(fetchLocation.rejected, (state, { payload }) => {
        state.errors = payload || [];
      })
      .addCase(fetchCity.fulfilled, (state, { payload }) => {
        state.errors = [];
        state.city = payload.results[0].city;
      })
      .addCase(fetchCity.rejected, (state, { payload }) => {
        state.errors = payload || [];
      });
  },
  initialState,
  name: 'header',
  reducers: {
    setSearch: (state, { payload }: PayloadAction<string>) => {
      state.search = payload;
      // const category = mockResults.filter(i => i.type === 'category');
      // const product = mockResults.filter(i => i.type === 'product');
      // state.searchCategory = category.filter(
      //   i => i.category?.name.toLowerCase() !== payload,
      // );
      // state.searchProducts = product.filter(
      //   i => i.product?.name.toLowerCase() !== payload,
      // );
    },
  },
});

export const { actions: headerActions } = headerSlice;
export const { reducer: headerReducer } = headerSlice;
