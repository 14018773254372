import { memo, useCallback, useState } from 'react';
import cls from './SecondFooter.module.scss';
import { ModalPrivacyPolicy } from '../ModalPrivacyPolicy/ModalPrivacyPolicy';
import { classNames } from '@/shared/lib/classNames/classNames';
import { PTag } from '@/shared/ui/Paragraph/P';

export interface SecondFooterProps {
  className?: string;
}

export const SecondFooter = memo((props: SecondFooterProps) => {
  const { className } = props;
  const [open, setOpen] = useState(false);

  const onOpenModal = useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <div className={classNames(cls.SecondFooter, {}, [className])}>
      <div>
        <PTag tage='desc' className={cls.componyName}>
          ©{new Date().getFullYear()} ИП Белкин Юрий Александрович
        </PTag>
      </div>
      <div className={cls.policy} onClick={onOpenModal}>
        <PTag tage='desc' className={cls.componyName}>
          Политика конфиденциальности
        </PTag>
      </div>
      <ModalPrivacyPolicy open={open} onClose={onClose} />
    </div>
  );
});
