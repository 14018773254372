import { memo, useCallback, useState } from 'react';
import { CategoriesMenu } from '@/entities/Categories';
import { Popover } from '@/shared/ui/Popover/Popover';
import { HStack } from '@/shared/ui/Stack';

export const Catalog = memo(() => {
  const [open, setOpen] = useState(false);
  const handleClick = useCallback((isOpen: boolean) => {
    setOpen(isOpen);
  }, []);

  return (
    <HStack align='center'>
      <Popover open={open} onHandleOpen={handleClick}>
        <CategoriesMenu onClose={() => setOpen(false)} />
      </Popover>
    </HStack>
  );
});
