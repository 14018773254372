import { memo } from 'react';
import cls from './CategoryTable.module.scss';
import { Skeleton } from '@/shared/ui/Skeleton';

export const CategorySkeleton = memo(() => (
  <table className={cls.table}>
    <tbody>
      <tr className={cls.tr}>
        {new Array(8).fill(0).map((item, index) => (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <td key={`${index}`}>
            <Skeleton
              key={index}
              className={cls.skeleton}
              height='100%'
              width='100%'
            />
          </td>
        ))}
      </tr>
    </tbody>
  </table>
));
