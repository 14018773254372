import { createAsyncThunk } from '@reduxjs/toolkit';
import { CartItem } from '../../types/cartSchema';
import { ThunkConfig } from '@/app/providers/StorProvider';

interface fetchCartsProps {
  replace: boolean;
}

export const getCartsReq = createAsyncThunk<
  CartItem,
  fetchCartsProps,
  ThunkConfig<string[]>
>('carts/getCartItems', async (_props, { extra, rejectWithValue }) => {
  try {
    const response = await extra.api.get<CartItem>('users/carts');
    return response.data;
  } catch (e) {
    return rejectWithValue(['service']);
  }
});
