import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNotAuthCart } from '../../selectors/getCartData/getCartData';
import { getCartsReq } from '../getCardItems/getCardItems';
import { ThunkConfig } from '@/app/providers/StorProvider';

export const addToCart = createAsyncThunk<string, void, ThunkConfig<string[]>>(
  'cart/addToCarts',
  async (_, { extra, rejectWithValue, getState, dispatch }) => {
    try {
      const carts = getNotAuthCart(getState());
      const items = carts.map(c => ({ productId: c.id, quantity: c.quantity }));
      await extra.api.post<void>('users/carts/sync', {
        items,
      });
      dispatch(getCartsReq({ replace: false }));
      return 'ok';
    } catch (e) {
      return rejectWithValue(['service']);
    }
  },
);
