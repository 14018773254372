import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from '@/app/providers/StorProvider';
import { getQueryParams } from '@/shared/lib/url';

export const getAllStores = (state: StateSchema) => state?.allStores?.allStores;
export const getStoresTabs = (state: StateSchema) =>
  state?.allStores?.storesTabs;
export const getErrors = (state: StateSchema) => state?.allStores.errors;
export const getErrorsTabs = (state: StateSchema) =>
  state?.allStores.errorsTabs;
export const getPage = (state: StateSchema) => state?.allStores.page ?? 1;
export const getTotalPage = (state: StateSchema) =>
  state?.allStores.totalPage ?? 1;
export const getSize = (state: StateSchema) => state?.allStores.size ?? 10;
export const getSearch = (state: StateSchema) => state?.allStores.search;
export const getIsLoading = (state: StateSchema) => state?.allStores.isLoading;
export const getIsTabsLoading = (state: StateSchema) =>
  state?.allStores.isTabsLoading;
export const getSelectCity = (state: StateSchema) =>
  state.allStores.selectedCity;

export const getTab = (state: StateSchema) =>
  state.allStores.selectedCity?.value ?? '';

export const getCity = createSelector(getTab, city => {
  if (city) {
    if (city === 'all') return undefined;
    return city;
  }
  return undefined;
});
export const getTabsItem = createSelector(getStoresTabs, store => {
  if (store) {
    const tabs = [{ label: 'Все', value: 'all' }];
    const stores = store.map(c => ({
      label: c,
      value: c,
    })) as Autocomplete<string>[];
    tabs.push(...stores);
    return tabs;
  }

  return [];
});

export const getQuery = createSelector(
  getSize,
  getSearch,
  getPage,
  getCity,
  (size, search, page, city) =>
    getQueryParams({
      'filter.city': city,
      limit: size.toString(),
      page: page.toString(),
      search,
    }),
);
