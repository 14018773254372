// import { createAsyncThunk } from '@reduxjs/toolkit';
// import { fetchCityRu } from './fetchCityRu';
// import { fetchLocation } from './fetchLocations';
// import { CityRuRes, ResData, ResultFetchCity } from '../../types/locations';
// import { ThunkConfig } from '@/app/providers/StorProvider';

// export const fetchCity = createAsyncThunk<
//   ResultFetchCity,
//   void,
//   ThunkConfig<string[]>
// >(
//   'headers/fetchCityData',
//   async (_, { extra, rejectWithValue, getState, dispatch }) => {
//     try {
//       const fetchCityEn = await dispatch(fetchLocation());
//       if (fetchCityEn.meta.requestStatus === 'rejected')
//         return rejectWithValue(['service']);
//       const cityEn = fetchCityEn.payload as ResData;

//       const fetchCityRuReq = await dispatch(fetchCityRu(cityEn.city.name));
//       if (fetchCityRuReq.meta.requestStatus === 'rejected')
//         return rejectWithValue(['service']);

//       const res = await Promise.all([fetchCityEn, fetchCityRuReq])
//         .then(([cityEnRes, cityRuRes]) => {
//           const cityData = cityEnRes.payload as ResData;
//           const cityInRu = cityRuRes.payload as CityRuRes;
//           return { cityData, cityInRu };
//         })
//         .catch(err => 'Что-то пошло не так, попробуйте чуть позже');
//       if (typeof res === 'string') return rejectWithValue([res]);

//       return res as unknown as ResultFetchCity;
//     } catch (e) {
//       return rejectWithValue(['Что-то пошло не так, попробуйте чуть позже']);
//     }
//   },
// );

import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCityRu } from './fetchCityRu';
import { fetchLocation } from './fetchLocations';
import { CityRuRes, ResData } from '../../types/locations';
import { ThunkConfig } from '@/app/providers/StorProvider';

export const fetchCity = createAsyncThunk<
  CityRuRes,
  void,
  ThunkConfig<string[]>
>(
  'headers/fetchLocationCity',
  async (_, { extra, rejectWithValue, getState, dispatch }) => {
    try {
      const fetchCityEn = await dispatch(fetchLocation());
      if (fetchCityEn.meta.requestStatus === 'rejected')
        return rejectWithValue(fetchCityEn.payload as string[]);
      const cityEn = fetchCityEn.payload as ResData;

      const fetchCityRuReq = await dispatch(fetchCityRu(cityEn.city.name));
      const res = await Promise.all([fetchCityEn, fetchCityRuReq])
        .then(([cityEnRes, cityRuRes]) => cityRuRes.payload as CityRuRes)
        .catch(err => 'service');
      if (typeof res === 'string') return rejectWithValue([res]);
      return res;
    } catch (e) {
      return rejectWithValue(['Что-то пошло не так, попробуйте чуть позже']);
    }
  },
);
